<template>
  <page-content>

    <common-table
      ref="table"
      path="web/profit/project"
      :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add" >新增</a-button>
      </template>


      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <project-edit ref="editDrawer" @success="getList"></project-edit>
  </page-content>
</template>
<script>
  import ProjectEdit from './ProjectEdit'
  export default {
    components: {
      ProjectEdit
    },
    data () {
      return {

      }
    },

    computed: {
      columns () {
        return [
          {
            title: '项目',
            dataIndex: 'name',
          },
          {
            title: '备注',
            dataIndex: 'remark'
          },
          {
            title: '分润次数',
            dataIndex: 'distributeCount'
          },
          {
            title: '累计分润金额',
            dataIndex: 'distributeMoney',
            customRender: (text, row, index) => {
              return text/100
            },
          },
          {
            title: '创建时间',
            dataIndex: 'createdAt'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' }
          }

        ]
      }
    },
    methods: {
      add () {
        this.$refs.editDrawer.show()
      },
      view(record){
        this.$router.push("/profit/project/"+record.id)
      },
      edit (record) {
        this.$refs.editDrawer.show(record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除记录?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('web/profit/project' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      getList () {
        this.$refs.table.getData()
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
